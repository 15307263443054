/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loading from "../../../assets/loading.png";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import checksService from "../../../services/checks";

/** MUI imports */
import { Box, Stack, Typography, Skeleton, IconButton, Button, Tabs, Tab } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ChatIcon from "../../../assets/chat-icon.svg";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import { addChangeRoute, addTutorial } from "../../../redux/userSlice";

import invoicesService from "../../../services/invoices";
import FiltersButtonInvoices from "../FiltersButtonInvoices";

import { fetchFileFromS3, zeroPad } from "../../../utils/utils";
import BaseTable from "../BaseTable";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        invoiceNumber: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        invoiceDate: <Skeleton variant="text" animation="wave" />,
        reference: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

const InvoicesTable = (props) => {
    const {
        conversions,
        usersFilters,
        company_id,
        setConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setConvertDoneNew,
        setCorrelative,
        setFormat,
        setConversionId,
        setFileNameSaved,
        setAccount,
        setShowPrev,
        setFileData,
        setFileNameFull,
        setConversionData,
        download,
        setDownload,
        setConvFormat,
        setGroupConversions,
        groupConversions,
        setOpenModalGroupedHist,
        setGroupedData,
        setGroupedPeriods,
        setOpenModalGrouped,
        setCorrelativeModalGrouped,
        setValue,
        value,
        handleShowPrevDownload,
        setModalType,
        integration,
        setShowSync,
        columns,
        setColumns,
        tableColumnExtensions,
        setTableColumnExtensions,
        columns1,
        columns12,
        columns2,
        tableColumnExtensions1,
        tableColumnExtensions2,
        setRowsModal,
        setOpenPreview,
        conversion_type,
        setBasePreviewData,
        setSelectedInv,
    } = props;

    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchState] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [rows, setRows] = useState([]);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [caseInfo, setCaseInfo] = useState({});
    const [selectionAcc, setSelectionAcc] = useState([]);
    const controllerRef = useRef(null);

    useEffect(() => {
        setSelectionAcc([]);
        setSearchState("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversion_type]);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const syncInv = async () => {
        const arraySync = [];

        selectionAcc.forEach((selectedRow) => {
            arraySync.push(rows[selectedRow]);
        });

        if (arraySync[0].grouped) {
            const groupedInv = arraySync.map((item) => item.id);
            let params2 = { grouped_invoice_id: groupedInv };
            await invoicesService
                .getGroupedInvoiceDetails(params2)
                .then(async (data) => {
                    const newData = data.flatMap((item) => item.invoices);
                    setConversionData({ invoices: newData });

                    setShowSync(true);
                    setShowPrev(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const singleInv = arraySync.map((item) => item.id);
            let params2 = { invoice_id: singleInv };
            await invoicesService
                .getAIInvoiceDetails(params2)
                .then((data) => {
                    setConversionData({ invoices: data });

                    setShowSync(true);
                    setShowPrev(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    useEffect(() => {
        if ((todos.steps.step8 || todos.steps.step9) && tutorial && download) {
            let number = "";

            if (todos.steps.step8) {
                number = "8";
            } else {
                number = "9";
            }
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} ${number}`,
                                element: "#account8-tutorial",
                                intro: t("miscellaneous.tutorialConv5"),
                            },
                        ],
                    })
                    .onexit(() => {
                        if (todos.steps.step8) {
                            dispatch(addTutorial({ ...todos.steps, step8: false }));
                        } else {
                            dispatch(addTutorial({ ...todos.steps, step9: false }));
                        }
                        setDownload(false);
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, download, setDownload, t, todos.steps, tutorial]);

    useEffect(() => {
        const handleShowPrev = async (convID, isGrouped) => {
            setOpenBackdrop(true);
            let converInfo = {};

            setSelectedInv({
                id: convID,
                isGrouped: isGrouped,
            });

            if (isGrouped) {
                let params2 = { grouped_invoice_id: convID };
                await invoicesService
                    .getGroupedInvoiceDetails(params2)
                    .then(async (data) => {
                        const newData = data;
                        let statusSucc = 0;
                        let statusRev = 0;
                        let statusConv = 0;
                        let statusHeavy = 0;
                        let finalStatus = 0;

                        for (const value of data.invoices) {
                            if (value.id_status === 9) {
                                statusSucc++;
                            } else if (value.id_status === 2) {
                                statusRev++;
                            } else if (value.id_status === 1) {
                                statusHeavy++;
                            } else if (value.id_status === 3) {
                                statusConv++;
                            }
                        }

                        if (statusSucc > 0) {
                            finalStatus = 9;
                        } else if (statusRev > 0) {
                            finalStatus = 2;
                        } else if (statusHeavy > 0) {
                            finalStatus = 1;
                        } else if (statusConv > 0) {
                            finalStatus = 3;
                        } else {
                            finalStatus = 10;
                        }
                        newData.id_status = finalStatus;

                        setConversionData(newData);
                        setGroupedData(newData);
                        converInfo = newData;
                        setFileNameSaved("GROUP-" + zeroPad(newData.correlative ?? 0, 5));
                        const filteredConv = newData.invoices.filter(
                            (item) =>
                                item.id_status === 3 ||
                                item.id_status === 4 ||
                                item.id_status === 5 ||
                                item.id_status === 6 ||
                                item.id_status === 9
                        );

                        // Ordena el arreglo por la fecha
                        const sortedFilteredConv = filteredConv.sort((a, b) => {
                            const dateA = moment.utc(a.formatted_invoice_date);
                            const dateB = moment.utc(b.formatted_invoice_date);
                            return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                        });
                        setGroupedPeriods(sortedFilteredConv);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setGroupedData(null);
                let params2 = { invoice_id: convID };
                await invoicesService
                    .getAIInvoiceDetails(params2)
                    .then((data) => {
                        setConversionData(data);
                        converInfo = data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            if (!isGrouped) {
                setCorrelative(converInfo.correlative);
                setConversionId(converInfo._id);
                setFileNameSaved(converInfo.uploaded_file.replace(/\.[^/.]+$/, ""));

                const data = await fetchFileFromS3(converInfo.file_name);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }
                setFileNameFull(converInfo.uploaded_file);

                setBasePreviewData({
                    id: converInfo._id,
                    type: "invoice",
                });
            } else {
                setCorrelative(converInfo.correlative);

                const data = await fetchFileFromS3(converInfo.invoices[0].file_name);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }
                setFileNameFull(converInfo.invoices[0].uploaded_file);

                setBasePreviewData({
                    id: converInfo.invoices[0]._id,
                    type: "invoice",
                });
            }

            dispatch(addChangeRoute(""));
            setShowPrev(true);
            setOpenBackdrop(false);
        };

        const connectSocket = async (case_id, correlative, status) => {
            setRoom(case_id);
            initiateSocket(case_id, todos.userInfo, "invoice", correlative);
            subscribeToChat((err, data) => {
                if (err) return;
                setChat((oldChats) => [...oldChats, data]);
            });
            newUserResponse((err, data) => {
                if (err) return;
                setUsers(data);
            });
            setOpen(true);
        };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const handleClickChat = async (statusConv, conversionID, correlative) => {
                setIsLoading(true);

                let params = {
                    company_id: todos.companyID,
                    franchise_id: todos.franchiseID,
                    user_email: todos.userInfo.email,
                    type: "conversion",
                    conversion_id: conversionID,
                    conversion_type: "invoice",
                };

                let caseInfo = null;

                await casesService
                    .getCaseInfo(params)
                    .then(async (response) => {
                        if (response) {
                            setCaseInfo(response.caseInfo);
                            caseInfo = response.caseInfo;
                            setStatus("");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (!caseInfo) {
                    await casesService
                        .createNewCase(params)
                        .then(async () => {
                            await casesService
                                .getCaseInfo(params)
                                .then(async (data) => {
                                    setCaseInfo(data.caseInfo);
                                    caseInfo = data.caseInfo;
                                    setStatus(1);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    setStatus(caseInfo.case_reason);
                }

                await connectSocket(caseInfo._id, correlative, statusConv);

                setIsLoading(false);
            };

            const showConversions = (index) => {
                setModalType("invoice");
                setOpenModalGroupedHist(true);
                setOpenModalGrouped(true);
                setCorrelativeModalGrouped(groupConversions[index].correlative);

                let newRows = groupConversions[index].conversions.map((item) => ({
                    id: item._id,
                    invoice: item.invoice_id ? item.invoice_id : "-",
                    contact: parseInt(item.conversion_type) === 1 ? item.customer_name : item.vendor_name ?? "-",
                    date: item.formatted_invoice_date
                        ? moment.utc(item.formatted_invoice_date).format(todos.dateFormat)
                        : "-",
                    amount: item.formatted_invoice_total
                        ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                              minimumFractionDigits: 2,
                          })
                        : "-",
                    number: item.correlative,
                    file_name: item.uploaded_file,
                    file_name_s3: item.file_name,
                    period: "",
                    status: item.id_status,
                }));
                setRowsModal(newRows);
            };

            const previewInvoice = async (filename, id) => {
                setIsLoading(true);

                const data = await fetchFileFromS3(filename);
                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }

                setBasePreviewData({
                    id: id,
                    type: "invoice",
                });

                setFileNameFull(filename);
                setOpenPreview(true);

                setIsLoading(false);
            };

            const successConv = conversions.filter((item) => item.id_status === 1).map((item) => item._id);
            const lastSuccess = successConv.length > 0 ? successConv[0] : null;

            if (conversions.length > 0) {
                // 1: En proceso
                // 2: En revisión
                // 3: Por sincronizar
                // 4: Por descargar
                // 5: Sincronizando
                // 6: Sincronizado
                // 7: Registro manual
                // 8: Sincronizado parcial
                // 9: Descargado
                // 10: Inválida

                setRows(
                    conversions.map((item) => ({
                        id: item._id,
                        number:
                            item.correlative != null && item.correlative !== undefined
                                ? "#" + zeroPad(item.correlative, 5)
                                : "",
                        uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                        invoiceNumber: item.invoice_id ? (
                            <LightTooltip title={item.invoice_id} aria-label="lastTrans">
                                <Button onClick={() => previewInvoice(item.file_name, item._id)}>
                                    {item.invoice_id}
                                </Button>
                            </LightTooltip>
                        ) : (
                            "-"
                        ),
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                              })
                            : "",
                        contact: parseInt(item.conversion_type) === 1 ? item.customer_name : item.vendor_name ?? "",
                        invoiceDate:
                            item.formatted_invoice_date === null
                                ? "N/A"
                                : item.formatted_invoice_date
                                ? moment.utc(item.formatted_invoice_date).format(todos.dateFormat)
                                : "",
                        user: item?.user_id?.user_name ?? "",
                        original_status: item.id_status,
                        grouped: false,
                        reference:
                            item.id_status === 6
                                ? ""
                                : item.balance === null
                                ? ""
                                : item.balance || parseFloat(item.balance) === 0
                                ? parseFloat(item.balance).toLocaleString(todos.amountFormat, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                  })
                                : "",
                        status: (
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                {(item.id_status === 1 || item.id_status === 5) && (
                                    <img src={Loading} alt="loading" width={20} />
                                )}
                                {item.id_status === 2 && (
                                    <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 3 && (
                                    <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                )}
                                {item.id_status === 4 && <DownloadIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />}
                                {(item.id_status === 6 || item.id_status === 7) && (
                                    <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                )}
                                {item.id_status === 8 && (
                                    <UploadFileIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 9 && <DownloadIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                                {(item.id_status === 10 || item.id_status === 11) && (
                                    <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />
                                )}
                                &nbsp;
                                <Typography>
                                    {item.id_status === 1
                                        ? t("converter.inProc")
                                        : item.id_status === 2
                                        ? t("converter.inReview")
                                        : item.id_status === 3
                                        ? t("converter.toBeSynch")
                                        : item.id_status === 4
                                        ? t("converter.convertible")
                                        : item.id_status === 5
                                        ? t("converter.synchronizing")
                                        : item.id_status === 6
                                        ? t("converter.synchronized")
                                        : item.id_status === 7
                                        ? t("converter.manualReg")
                                        : item.id_status === 8
                                        ? t("converter.partialSync")
                                        : item.id_status === 9
                                        ? t("converter.downloaded")
                                        : item.id_status === 10
                                        ? t("converter.invalid2")
                                        : t("navBar.cancelled")}
                                </Typography>
                            </Stack>
                        ),
                        chat:
                            item.id_status === 1 || item.id_status === 2 || item.id_status >= 5 ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <LightTooltip title={t("converter.tooltipChat")} position="top">
                                        <IconButton
                                            onClick={() =>
                                                handleClickChat(
                                                    item.id_status,
                                                    item._id,
                                                    `#${zeroPad(item.correlative, 5)}`
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                        >
                                            <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                        </IconButton>
                                    </LightTooltip>
                                    {item.id_status >= 6 && item.id_status <= 9 && (
                                        <IconButton
                                            onClick={() => handleShowPrev(item._id, false)}
                                            size="small"
                                            color="primary"
                                            id={
                                                lastSuccess !== null && lastSuccess === item._id
                                                    ? "account8-tutorial"
                                                    : ""
                                            }
                                        >
                                            <DownloadIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Stack>
                            ) : item.id_status === 3 || item.id_status === 4 ? (
                                <LightTooltip title={t("converter.seeDetails")}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleShowPrev(item._id, false)}
                                        color="primary"
                                    >
                                        <VisibilityIcon color="primary" sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </LightTooltip>
                            ) : (
                                ""
                            ),
                    }))
                );
            } else if (groupConversions.length > 0) {
                setRows(
                    groupConversions.map((item, index) => {
                        const groupedPeriods = item.conversions.filter(
                            (item) =>
                                item.id_status === 3 ||
                                item.id_status === 4 ||
                                item.id_status === 5 ||
                                item.id_status === 6 ||
                                item.id_status === 9
                        );

                        // Ordena el arreglo por la fecha
                        const sortedFilteredConv = groupedPeriods.sort((a, b) => {
                            const dateA = moment.utc(a.formatted_invoice_date);
                            const dateB = moment.utc(b.formatted_invoice_date);
                            return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                        });

                        // 1: En proceso
                        // 2: En revisión
                        // 3: Por sincronizar
                        // 4: Por descargar
                        // 5: Sincronizando
                        // 6: Sincronizado
                        // 7: Registro manual
                        // 8: Sincronizado manual
                        // 9: Descargado
                        // 10: Inválida
                        // 11: Cancelada

                        let finalStatus = 0;
                        let partial = 0;
                        if (
                            item.conversions.every(
                                (value) =>
                                    value.id_status === 9 ||
                                    value.id_status === 6 ||
                                    value.id_status === 7 ||
                                    value.id_status === 11
                            )
                        ) {
                            // Descargado, Sincronizado, Registro manual, Cancelada (Estatus final)
                            finalStatus = item.conversions[0].id_status;
                        } else if (item.conversions.every((value) => value.id_status === 10)) {
                            // Inválida
                            finalStatus = 10;
                        } else if (item.conversions.some((value) => value.id_status === 2)) {
                            // En revisión
                            finalStatus = 2;
                            if (
                                item.conversions.some(
                                    (value) =>
                                        value.id_status === 3 ||
                                        value.id_status === 4 ||
                                        value.id_status === 6 ||
                                        value.id_status === 7 ||
                                        value.id_status === 9
                                )
                            ) {
                                partial = 1;
                            }
                        } else if (item.conversions.some((value) => value.id_status === 3)) {
                            // Por sincronizar
                            if (item.conversions.some((value) => value.id_status === 6)) {
                                finalStatus = 8;
                            } else {
                                finalStatus = 3;
                            }
                        } else if (item.conversions.some((value) => value.id_status === 4)) {
                            // Por descargar
                            finalStatus = 4;
                        } else if (item.conversions.some((value) => value.id_status === 1)) {
                            // En proceso
                            finalStatus = 1;
                        } else if (item.conversions.some((value) => value.id_status === 5)) {
                            // Sincronizando
                            finalStatus = 5;
                        }

                        return {
                            id: item._id,
                            number: (
                                <Button size="small" onClick={() => showConversions(index)}>
                                    {item.correlative != null && item.correlative !== undefined
                                        ? "GROUP-" + zeroPad(item.correlative, 5)
                                        : ""}
                                </Button>
                            ),
                            uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                            invoiceNumber: "-",
                            amount:
                                sortedFilteredConv.length === 0
                                    ? "-"
                                    : parseFloat(
                                          sortedFilteredConv.reduce((sum, item) => {
                                              return sum + item.formatted_invoice_total;
                                          }, 0)
                                      ).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      }),
                            contact:
                                groupedPeriods.length === 0 || !groupedPeriods[0].initial_date
                                    ? "N/A"
                                    : moment.utc(groupedPeriods[0].initial_date).format(todos.dateFormat),
                            original_status: finalStatus,
                            grouped: true,
                            invoiceDate:
                                sortedFilteredConv.length === 0 || !sortedFilteredConv[0].formatted_invoice_date
                                    ? "-"
                                    : sortedFilteredConv.filter((item) => item.formatted_invoice_date !== null).length >
                                      1
                                    ? moment
                                          .utc(
                                              sortedFilteredConv.filter(
                                                  (item) => item.formatted_invoice_date !== null
                                              )[0].formatted_invoice_date
                                          )
                                          .format(todos.dateFormat) +
                                      " - " +
                                      moment
                                          .utc(
                                              sortedFilteredConv.filter((item) => item.formatted_invoice_date !== null)[
                                                  sortedFilteredConv.filter(
                                                      (item) => item.formatted_invoice_date !== null
                                                  ).length - 1
                                              ].formatted_invoice_date
                                          )
                                          .format(todos.dateFormat)
                                    : groupedPeriods.filter((item) => item.formatted_invoice_date !== null).length === 1
                                    ? moment
                                          .utc(
                                              sortedFilteredConv.filter(
                                                  (item) => item.formatted_invoice_date !== null
                                              )[0].formatted_invoice_date
                                          )
                                          .format(todos.dateFormat) +
                                      " - " +
                                      moment
                                          .utc(
                                              sortedFilteredConv.filter(
                                                  (item) => item.formatted_invoice_date !== null
                                              )[0].formatted_invoice_date
                                          )
                                          .format(todos.dateFormat)
                                    : "-",
                            user: item.conversions[0].user_id.user_name,
                            reference:
                                finalStatus === 6 || item.conversions[item.conversions.length - 1].balance === null
                                    ? ""
                                    : item.conversions[item.conversions.length - 1].balance ||
                                      parseFloat(item.conversions[item.conversions.length - 1].balance) === 0
                                    ? parseFloat(item.conversions[item.conversions.length - 1].balance).toLocaleString(
                                          todos.amountFormat,
                                          {
                                              minimumFractionDigits: 2,
                                          }
                                      )
                                    : "",
                            status: (
                                <Stack direction="row" alignItems="center">
                                    {(finalStatus === 1 || finalStatus === 5) && (
                                        <img src={Loading} alt="loading" width={20} />
                                    )}
                                    {finalStatus === 2 && (
                                        <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 3 && (
                                        <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 4 && <DownloadIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />}
                                    {(finalStatus === 6 || finalStatus === 7) && (
                                        <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 8 && (
                                        <UploadFileIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 9 && <DownloadIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                                    {finalStatus === 10 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                                    &nbsp;
                                    <Typography>
                                        {finalStatus === 1
                                            ? t("converter.inProc")
                                            : finalStatus === 2
                                            ? t("converter.inReview")
                                            : finalStatus === 3
                                            ? t("converter.toBeSynch")
                                            : finalStatus === 4
                                            ? t("converter.convertible")
                                            : finalStatus === 5
                                            ? t("converter.synchronizing")
                                            : finalStatus === 6
                                            ? t("converter.synchronized")
                                            : finalStatus === 7
                                            ? t("converter.manualReg")
                                            : finalStatus === 8
                                            ? t("converter.partialSync")
                                            : finalStatus === 9
                                            ? t("converter.downloaded")
                                            : t("converter.invalid2")}
                                    </Typography>
                                </Stack>
                            ),
                            chat:
                                finalStatus === 1 || finalStatus === 2 || finalStatus >= 5 ? (
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {/* <LightTooltip title={t("converter.tooltipChat")} position="top">
                                            <IconButton
                                                onClick={() =>
                                                    handleClickChat(
                                                        finalStatus,
                                                        item._id,
                                                        `#${zeroPad(item.correlative, 5)}`
                                                    )
                                                }
                                                size="small"
                                                color="primary"
                                            >
                                                <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                            </IconButton>
                                        </LightTooltip> */}
                                        {((finalStatus >= 5 && finalStatus <= 9) || partial === 1) && (
                                            <IconButton
                                                onClick={() => handleShowPrev(item._id, true)}
                                                size="small"
                                                color="primary"
                                                id={
                                                    lastSuccess !== null && lastSuccess === item._id
                                                        ? "account8-tutorial"
                                                        : ""
                                                }
                                            >
                                                <DownloadIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Stack>
                                ) : finalStatus === 3 || finalStatus === 4 ? (
                                    <IconButton
                                        onClick={() => handleShowPrev(item._id, true)}
                                        size="small"
                                        color="primary"
                                    >
                                        <VisibilityIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                ) : (
                                    ""
                                ),
                        };
                    })
                );
            } else {
                setRows([]);
            }
        }
    }, [
        conversions,
        groupConversions,
        language,
        setAccount,
        setConvFormat,
        setConversionData,
        setConversionId,
        setConvertDoneNew,
        setCorrelative,
        setFileData,
        setFileNameFull,
        setFileNameSaved,
        setFormat,
        setGroupedData,
        setGroupedPeriods,
        setIsLoading,
        setOpenModalGrouped,
        setOpenModalGroupedHist,
        setCorrelativeModalGrouped,
        setShowPrev,
        showSkeleton,
        t,
        todos.amountFormat,
        todos.companyID,
        todos.dateFormat,
        todos.franchiseID,
        todos.userInfo,
        handleShowPrevDownload,
        setModalType,
        setRowsModal,
        setOpenPreview,
        setBasePreviewData,
        dispatch,
        setSelectedInv,
    ]);

    const downloadReport = async () => {
        setIsLoading(true);

        let fileName = `InvoiceHistory-${moment().format("MMDDYYYY")}.csv`;
        let conversionIds = rows?.map((e) => e.id);

        if (!showSkeleton && conversionIds?.join(",") !== "0,1,2,3,4,5,6,7,8,9") {
            if (selectionAcc?.length > 0) {
                let finalIds = [];
                conversionIds.forEach((e, i) => {
                    if (selectionAcc.includes(i)) {
                        finalIds.push(e);
                    }
                });

                conversionIds = finalIds;
            }

            conversionIds = conversionIds?.length > 0 ? conversionIds.join(",") : "";

            await checksService
                .downloadHistoryInvoices({
                    company_id: todos.companyID,
                    file_name: fileName,
                    conversionIds: conversionIds,
                })
                .then((response) => {
                    // Crea un enlace temporal y haz clic en él para iniciar la descarga
                    const link = document.createElement("a");
                    link.href = response.url;
                    document.body.appendChild(link);
                    link.click();

                    // Limpia el enlace temporal
                    document.body.removeChild(link);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setIsLoading(false);
    };

    const attributes = [
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        //{ id: 3, name: t("converter.format"), query: "format" },
        //{ id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_id" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const handleChangeTab = async (event, newValue) => {
        setShowSkeleton(true);
        if (newValue === 1) {
            setTableColumnExtensions(tableColumnExtensions2);
            setColumns(columns2);
        } else {
            setTableColumnExtensions(tableColumnExtensions1);
            if (conversion_type === 1) {
                setColumns(columns1);
            } else {
                setColumns(columns12);
            }
        }
        setValue(newValue);
        let params = { company_id: todos.companyID, conversion_type };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let aux = [...filters.filtersArray];

            let objectFinal = {};

            aux.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            params = {
                ...params,
                ...objectFinal,
            };
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        const options = {
            signal: controllerRef.current.signal,
        };

        if (newValue === 1) {
            await invoicesService
                .getGroupedInvoiceConversions(params, options)
                .then((data) => {
                    setConversions([]);
                    setGroupConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await invoicesService
                .getInvoiceConversions(params, options)
                .then((response) => {
                    setGroupConversions([]);
                    setConversions(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        controllerRef.current = null;
        setShowSkeleton(false);
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    backgroundColor: "#F8F8F9",
                    pt: 3,
                    borderRadius: 2,
                }}
            >
                <GridUI container spacing={2} alignItems="center" justifyContent="space-between">
                    <GridUI item>
                        <Typography variant="h1">
                            {conversion_type === 1 ? t("converter.separateInvHist") : t("converter.separateBillsHist")}
                        </Typography>
                    </GridUI>
                    <GridUI item xs={12} md={6} lg={4}>
                        <GridUI container spacing={2} alignItems="center" justifyContent="flex-end">
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={downloadReport}
                                    disableElevation
                                    variant="outlined"
                                    endIcon={<DownloadIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    {t("converter.download")}
                                </Button>
                            </GridUI>
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={syncInv}
                                    disableElevation
                                    variant="contained"
                                    endIcon={<SyncIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                    }}
                                    disabled={
                                        selectionAcc.length === 0 ||
                                        selectionAcc.some((item) => rows[item].original_status !== 3) ||
                                        (integration !== 1 && integration !== 2)
                                    }
                                >
                                    {t("converter.sync")}
                                </Button>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                </GridUI>
                <GridUI container justifyContent="space-between" alignItems="end" spacing={2}>
                    <GridUI item sx={{ mb: { xs: 1, md: -8.3 }, zIndex: 1, mr: { xs: 0, md: 2 } }}>
                        <Tabs
                            orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            aria-label="Horizontal tabs"
                            sx={{
                                p: 1,
                                mt: 2,
                            }}
                            TabIndicatorProps={{
                                style: { display: "none" },
                            }}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab label={t("converter.singles")} {...a11yProps(0)} sx={TabProps} />
                            <Tab label={t("converter.groupedConvs")} {...a11yProps(1)} sx={TabProps} />
                        </Tabs>
                    </GridUI>
                    <GridUI item sx={{ mb: { xs: 1, md: -8.3 }, zIndex: 1, mr: { xs: 0, md: 37 } }}>
                        <FiltersButtonInvoices
                            users={usersFilters}
                            company={company_id}
                            setConversions={setConversions}
                            setShowSkeleton={setShowSkeleton}
                            franchise={franchise_id}
                            grupal={grupal}
                            setFilters={setFilters}
                            filters={filters}
                            setGroupConversions={setGroupConversions}
                            tabValue={value}
                            invoiceType="invoice"
                            conversion_type={conversion_type}
                        />
                    </GridUI>
                    <GridUI item xs={12}>
                        <BaseTable
                            rows={rows}
                            columns={columns}
                            selectionAcc={selectionAcc}
                            setSelectionAcc={setSelectionAcc}
                            searchValue={searchValue}
                            setSearchState={setSearchState}
                            tableColumnExtensions={tableColumnExtensions}
                        />
                    </GridUI>
                </GridUI>
            </Box>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            <SimpleBackdrop open={openBackdrop} />
            {open && (
                <ChatModal
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type={status}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    setCaseInfo={setCaseInfo}
                    caseInfo={caseInfo}
                />
            )}
        </>
    );
};

export default InvoicesTable;
