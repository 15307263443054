/* Componentes */
import {
    Box,
    Typography,
    Tabs,
    Tab,
    Grid,
    TextField,
    Button,
    Stack,
    Card,
    CardContent,
    Container,
    Chip,
    Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
//import SubscriptionTab1 from "./SubscriptionTab1";
import SubscriptionTab2 from "./SubscriptionTab2";
import SubscriptionTab3 from "./SubscriptionTab3";
import AlertDialog from "../ui/AlertDialog";
import subscriptionService from "../../services/subscription";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import paymentMethodsService from "../../services/payment_methods";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

/* Estilos */
import classes from "./MainSubscriptions.module.css";
import ShoppingCart from "./shoppingCart/ShoppingCart";
import BaseSnackbar from "../ui/BaseSnackbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

const MainSubscription = (props) => {
    const {
        franchise_id,
        company_id,
        cartItems,
        setCartItems,
        creditsInfo,
        plansInfo,
        currentPlan,
        activePlan,
        setActivePlan,
        //plansDraw,
        setPlansDraw,
        customCredits,
        setCustomCredits,
        planCredits,
        setPlanCredits,
        setIsLoading,
        currentPlanInfo,
        setCurrentPlanInfo,
        setReload,
        shoppingCartOpen,
        setShoppingCartOpen,
        payments,
        setPayments,
        showSkeleton,
        setShowSkeleton,
        //freePlan,
        mainSkeleton,
        franchiseDetails,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const location = useLocation();

    const [value, setValue] = useState(2);

    var userEmail = "";
    if (todos) {
        if (todos.userInfo) {
            if (todos.userInfo.email) {
                userEmail = todos.userInfo.email;
            }
        }
    }

    const [openAlert, setOpenAlert] = useState(false);
    const [emailCustomPlan, setEmailCustomPlan] = useState(null);
    const [numCustomPlan, setNumCustomPlan] = useState(null);
    const [isError, setIsError] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const navigate = useNavigate();

    const handleChangeTab = async (event, newValue) => {
        setValue(newValue);
        setShoppingCartOpen(false);
        if (newValue === 1) {
            reloadData();
        }
        navigate("/subscription", {
            state: {
                tab: newValue,
            },
        });
    };

    const reloadData = async () => {
        setShowSkeleton(true);

        await paymentMethodsService
            .getPaymentMethods({
                franchise_id: todos.franchiseID,
            })
            .then((response) => {
                setPayments(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setShowSkeleton(false);
    };

    const handleCloseDialog = () => {
        setEmailCustomPlan("");
        setNumCustomPlan("");
        setOpenAlert(false);
    };

    const handleOpenCart = () => {
        setShoppingCartOpen(true);
        reloadData();
    };

    const handleCustomPlan = async () => {
        if (emailCustomPlan && numCustomPlan) {
            setIsLoading(true);

            let params = {
                user_email: emailCustomPlan,
                user_phone: numCustomPlan,
            };

            await subscriptionService
                .requestCustomSubscription(params)
                .then(() => {
                    setOpenAlert(false);
                    setAlertType("success");
                    setTexto(t("subscription.sendRequest"));
                    setShow(true);
                })
                .catch((error) => {
                    console.log(error);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                    setOpenAlert(true);
                });
            setIsLoading(false);
        } else {
            setIsError(true);
        }
    };

    const handleChangeEmail = (e) => {
        setEmailCustomPlan(e.target.value);
    };

    const handleChangeNum = (e) => {
        if (!isNaN(e.target.value)) {
            setNumCustomPlan(e.target.value);
        }
    };

    useEffect(() => {
        if (location?.search?.includes("tab=0") || location.state?.tab === 0) {
            setValue(2);
        } else if (location?.search?.includes("tab=1") || location.state?.tab === 1) {
            setValue(2);
        } else if (location?.search?.includes("tab=2") || location.state?.tab === 2) {
            setValue(2);
        }
    }, [location?.search, location.state?.tab]);

    const bodyMess = (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} mt={3} justifyContent="center">
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        textAlign="justify"
                        sx={{
                            color: "#131F3E",
                        }}
                    >
                        {t("plans.personalizedMsg")}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} mt={1}>
                    <TextField
                        fullWidth
                        label={t("plans.email")}
                        value={emailCustomPlan}
                        onChange={handleChangeEmail}
                        variant="filled"
                        type="email"
                        error={isError && (emailCustomPlan === "" || !emailCustomPlan)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} mt={1}>
                    <TextField
                        fullWidth
                        label={t("plans.phone")}
                        value={numCustomPlan}
                        onChange={handleChangeNum}
                        variant="filled"
                        error={isError && (numCustomPlan === "" || !numCustomPlan)}
                    />
                </Grid>
            </Grid>
        </>
    );

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    return (
        <>
            <Card sx={{ border: "none", borderRadius: 0, boxShadow: 0 }}>
                <CardContent sx={{ mt: 1, border: "none", borderRadius: 0 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Tabs
                            orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            value={value}
                            onChange={handleChangeTab}
                            aria-label="Horizontal tabs"
                            sx={{
                                p: 1,
                                border: "none",
                            }}
                            TabIndicatorProps={{
                                style: { display: "none" },
                            }}
                        >
                            {userEmail !== "support@kiiper.app" && (
                                <Tab
                                    label={
                                        todos.subscription?.value === 1
                                            ? t("subscription.prices")
                                            : t("subscription.subsPlan")
                                    }
                                    {...a11yProps(0)}
                                    sx={{ display: "none" }}
                                    //sx={TabProps}
                                />
                            )}
                            <Tab
                                label={t("subscription.myPayments")}
                                {...a11yProps(userEmail !== "support@kiiper.app" ? 1 : 0)}
                                sx={{ display: "none" }}
                                //sx={TabProps}
                            />
                            <Tab
                                label={t("subscription.conversBalance")}
                                {...a11yProps(userEmail !== "support@kiiper.app" ? 2 : 1)}
                                sx={TabProps}
                            />
                        </Tabs>
                        {cartItems.length !== 0 && userEmail !== "support@kiiper.app" && (
                            <Button
                                variant="contained"
                                size="small"
                                className={shoppingCartOpen ? classes.shoppingBtnSel : classes.shoppingBtn}
                                startIcon={<ShoppingCartIcon />}
                                onClick={handleOpenCart}
                            >
                                {t("subscription.cart")}
                                <Chip
                                    className={classes.number}
                                    size="small"
                                    label={cartItems.length}
                                    color="primary"
                                />
                            </Button>
                        )}
                    </Stack>
                </CardContent>
            </Card>
            <Divider />
            <Box sx={{ background: value !== 2 ? "rgba(19, 31, 62, 0.03)" : "white" }}>
                <Container maxWidth="lg">
                    {shoppingCartOpen ? (
                        <ShoppingCart
                            franchise_id={franchise_id}
                            company_id={company_id}
                            cartItems={cartItems}
                            setCartItems={setCartItems}
                            setShoppingCartOpen={setShoppingCartOpen}
                            setIsLoading={setIsLoading}
                            setActivePlan={setActivePlan}
                            activePlan={activePlan}
                            customCredits={customCredits}
                            setCustomCredits={setCustomCredits}
                            planCredits={planCredits}
                            plansInfo={plansInfo}
                            currentPlan={currentPlan}
                            setPlanCredits={setPlanCredits}
                            creditsInfo={creditsInfo}
                            setPlansDraw={setPlansDraw}
                            currentPlanInfo={currentPlanInfo}
                            setCurrentPlanInfo={setCurrentPlanInfo}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            payments={payments}
                            setReload={setReload}
                            setValue={setValue}
                            reloadData={reloadData}
                            mainSkeleton={mainSkeleton}
                            franchiseDetails={franchiseDetails}
                        />
                    ) : (
                        <>
                            {/* <TabPanel value={value} index={userEmail !== "support@kiiper.app" ? 0 : -1}>
                                <SubscriptionTab1
                                    creditsInfo={creditsInfo}
                                    cartItems={cartItems}
                                    setCartItems={setCartItems}
                                    setIsLoading={setIsLoading}
                                    plansInfo={plansInfo}
                                    currentPlan={currentPlan}
                                    activePlan={activePlan}
                                    setActivePlan={setActivePlan}
                                    plansDraw={plansDraw}
                                    setPlansDraw={setPlansDraw}
                                    customCredits={customCredits}
                                    setCustomCredits={setCustomCredits}
                                    planCredits={planCredits}
                                    setPlanCredits={setPlanCredits}
                                    handleOpenCart={handleOpenCart}
                                    currentPlanInfo={currentPlanInfo}
                                    setReload={setReload}
                                    freePlan={freePlan}
                                    mainSkeleton={mainSkeleton}
                                />
                            </TabPanel> */}
                            <TabPanel value={value} index={userEmail !== "support@kiiper.app" ? 1 : 0}>
                                <SubscriptionTab2
                                    payments={payments}
                                    reloadData={reloadData}
                                    franchise_id={franchise_id}
                                    company_id={company_id}
                                    showSkeleton={showSkeleton}
                                    setShowSkeleton={setShowSkeleton}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={userEmail !== "support@kiiper.app" ? 2 : 1}>
                                <SubscriptionTab3
                                    setIsLoading={setIsLoading}
                                    franchise_id={franchise_id}
                                    company_id={company_id}
                                    showSkeleton={showSkeleton}
                                    setShowSkeleton={setShowSkeleton}
                                    plansInfo={plansInfo}
                                />
                            </TabPanel>
                        </>
                    )}
                </Container>
            </Box>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"customPlan"}
                title={t("plans.personalizedPlans")}
                message={bodyMess}
                agreeAction={() => handleCustomPlan()}
                maxWidth="sm"
            />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
        </>
    );
};

export default MainSubscription;
