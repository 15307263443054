import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import classes from "../../dashboard/DashboardBreadcrumbs.module.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PreviewIcon from "@mui/icons-material/Preview";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";

import companyService from "../../../services/company";
import conversionService from "../../../services/conversion";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LightTooltip from "../../ui/LightTooltip";
import Slider from "react-slick";
import { fetchFileFromS3 } from "../../../utils/utils";

const boxRed = { backgroundColor: "#FFD9DF", px: 2, py: 0.5, borderRadius: 2 };

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

function Arrows(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", pointerEvents: isDisabled ? "none" : "all" }}
            onClick={onClick}
        />
    );
}

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const ConsolidatedInvoicesPreview = (props) => {
    const {
        removeBanner,
        handleClickHelp,
        isHistory,
        setRowsModal,
        reloadInvoices,
        setShowHistSync,
        setShowSyncDetails,
        fileNameFull,
        setIsLoading,
        conversionData,
        setOpenPreview,
        setFileData,
        setBasePreviewData,
        setFileNameFull,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [companyInfo, setCompanyInfo] = useState(null);
    const [franchiseInfo, setFranchiseInfo] = useState(null);
    const [invoiceFields, setInvoiceFields] = useState({});

    const settings = {
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        nextArrow: <Arrows isDisabled={conversionData.headers[0].length <= 4} />,
        prevArrow: <Arrows isDisabled={conversionData.headers[0].length <= 4} />,
        draggable: conversionData.headers[0].length > 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 1360,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    useEffect(() => {
        (async () => {
            await companyService
                .getFranchiseDetails(todos.franchiseID)
                .then((data) => {
                    setFranchiseInfo(data);
                })
                .catch((error) => {
                    console.log(error);
                });

            await companyService
                .getCompanyDetails(todos.companyID)
                .then((data) => {
                    setCompanyInfo(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        })();
    }, [todos.companyID, todos.franchiseID]);

    const breadcrumbs = [
        <Typography key="1" variant="h1" color="#131F3E" className={classes.breadcrumbs}>
            {franchiseInfo?.franchise_name}
        </Typography>,
        <Typography key="2" variant="h1" color="#131F3E" className={classes.breadcrumbs}>
            {companyInfo?.company_name}
        </Typography>,
        <Typography key="3" variant="h1" color="primary" className={classes.breadcrumbs}>
            {t("converter.loadConsDoc")}
        </Typography>,
    ];

    const handleGoBack = () => {
        removeBanner();
        setRowsModal([]);
        setShowSyncDetails(false);
        setShowHistSync(false);
        reloadInvoices();
    };

    const syncConsolidatedInvoices = async () => {
        setIsLoading(true);

        // Sincronizamos las facturas
        const params = {
            invoiceFields: invoiceFields,
            invoiceConvID: conversionData._id,
        };

        await conversionService
            .syncConsolidatedInvoices(params)
            .then(() => {
                setShowSyncDetails(true);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const downloadInv = async () => {
        setIsLoading(true);
        // Sincronizamos las facturas
        const params = {
            invoiceConvID: conversionData._id,
        };
        const newArr = Object.entries(invoiceFields);

        for (let i = 0; i < newArr.length; i++) {
            params[newArr[i][0]] = newArr[i][1];
        }

        await conversionService
            .downloadConsolidatedInvoice(params)
            .then(async (response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const handlePreviewDoc = async () => {
        setIsLoading(true);

        const data = await fetchFileFromS3(conversionData.file_name);
        setIsLoading(false);

        if (data) {
            setFileData(data);
        }

        setFileNameFull(conversionData.file_name);

        setBasePreviewData({
            id: conversionData._id,
            type: "consolidated",
        });

        setIsLoading(false);
        setOpenPreview(true);
    };

    const handleChangeInvField = (event) => {
        setInvoiceFields({ ...invoiceFields, [event.target.name]: event.target.value });
    };

    const requiredFields = [
        { value: "InvoiceNum", name: t("converter.invoiceNumber") },
        { value: "Contact", name: t("subscription.contact") },
        { value: "Date", name: t("payment.date") },
        { value: "Description", name: t("payment.description") },
        { value: "UnitAmount", name: t("payment.unitPrice") },
        { value: "AccountCode", name: t("accounts.accountNum2") },
        { value: "TaxType", name: t("converter.typeTax") },
    ];

    const allFields = [
        { value: "InvoiceNum", name: t("converter.invoiceNumber") },
        { value: "Contact", name: t("subscription.contact") },
        { value: "Date", name: t("payment.date") },
        { value: "Description", name: t("payment.description") },
        { value: "LineAmount", name: t("converter.lineTotal") },
        { value: "Quantity", name: t("converter.qty") },
        { value: "UnitAmount", name: t("payment.unitPrice") },
        { value: "AccountCode", name: t("accounts.accountNum2") },
        { value: "TaxType", name: t("converter.typeTax") },
        { value: "DueDate", name: t("converter.dueDate") },
        { value: "Reference", name: t("accounts.ref") },
        { value: "ItemCode", name: "Item code" },
        { value: "Currency", name: t("dashboard.currency") },
        { value: "Discount", name: t("converter.discount") },
        { value: "Tracking 1", name: "Tracking 1" },
        { value: "Tracking 2", name: "Tracking 2" },
        { value: "TaxID", name: "Tax ID" },
        { value: "ContactNum", name: t("converter.contactNum") },
        { value: "Email", name: t("converter.email") },
        { value: "Address", name: t("converter.address") },
    ];

    const isValid = requiredFields.every((item) => Object.values(invoiceFields).includes(item.value));

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: "white" }}>
            <Box pt={5} mb={3}>
                <Button variant="outlined" onClick={handleGoBack}>
                    {isHistory ? t("inbox.goBackList") : t("converter.goBackConver")}
                </Button>
            </Box>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            <Button color="primary" sx={{ mt: 1 }} endIcon={<PreviewIcon />} onClick={handlePreviewDoc}>
                {fileNameFull}
            </Button>
            <Box sx={{ backgroundColor: "#F8F8F9", px: 3, py: 2, borderRadius: 4, mt: 2 }}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <Stack direction="row" justifyContent="center" spacing={1} alignItems="center">
                            <Typography>{t("converter.consoliAssign")}</Typography>
                            <LightTooltip title={t("converter.tooltipAssign")}>
                                <InfoIcon fontSize="small" sx={{ color: "#4A22D4" }} />
                            </LightTooltip>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack alignItems="flex-end">
                            <Typography variant="h1">{t("converter.haveProblem")}</Typography>
                            <Button onClick={() => handleClickHelp("conversion", "consolidated", conversionData._id)}>
                                {t("converter.contactUs")}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={2} sx={{ my: 0.2 }}>
                    <Grid item>
                        <Typography variant="h2">{t("converter.requiredFields")}:</Typography>
                    </Grid>
                    {isValid && (
                        <Grid item>
                            <Typography variant="h3" sx={{ color: "#34BD6A" }}>
                                {t("converter.readyToSync")}
                            </Typography>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("InvoiceNum") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("converter.invoiceNumber")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("Contact") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("subscription.contact")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("Date") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("payment.date")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("Description") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("payment.description")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("UnitAmount") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("payment.unitPrice")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("AccountCode") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("accounts.accountNum2")}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {!Object.values(invoiceFields).includes("TaxType") && (
                        <Grid item>
                            <Box sx={boxRed}>
                                <Typography variant="h2">{t("converter.typeTax")}</Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Box sx={{ pl: 2, width: { md: 1200, lg: 1700, xl: 2300 } }}>
                    <Slider {...settings}>
                        {Object.entries(conversionData.headers[0]).map((item, index) => (
                            <Box key={item[0]} sx={{ p: 2 }}>
                                <LightTooltip title={item[1]}>
                                    <Typography
                                        variant="h2"
                                        sx={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                        gutterBottom
                                    >
                                        {item[1]}
                                    </Typography>
                                </LightTooltip>
                                <FormControl variant="filled" fullWidth error={!invoiceFields[item[1]]}>
                                    <InputLabel
                                        id="simple-select-label-all"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("converter.assignField")}
                                    </InputLabel>
                                    <Select
                                        labelId="simple-select-label-all"
                                        name={item[1]}
                                        value={invoiceFields[item[1]] ?? ""}
                                        onChange={handleChangeInvField}
                                        IconComponent={(props) => checkIconDown(props)}
                                        size="small"
                                        sx={{
                                            bgcolor: invoiceFields[item[1]] ? "#dbf4e5" : "auto",
                                            "&.Mui-focused": {
                                                backgroundColor: invoiceFields[item[1]] ? "#dbf4e5" : "auto",
                                            },
                                        }}
                                        MenuProps={MenuProps}
                                    >
                                        {allFields.map((item) => (
                                            <MenuItem value={item.value} key={item.value}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {conversionData.body.map((itemEntry, index) => (
                                    <Box
                                        key={"row" + index}
                                        sx={{
                                            p: 2,
                                            backgroundColor: "white",
                                            borderRadius: 4,
                                            mb: 2,
                                            mt: 2,
                                            height: 50,
                                        }}
                                    >
                                        {Object.entries(itemEntry).map(
                                            (aux) =>
                                                aux[0] === item[0] && (
                                                    <Typography
                                                        key={aux[0]}
                                                        sx={{
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {aux[1]}
                                                    </Typography>
                                                )
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Slider>
                </Box>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <Button variant="outlined" endIcon={<DownloadIcon />} onClick={downloadInv} disabled={!isValid}>
                        {t("payment.download")}
                    </Button>
                    <Button
                        variant="contained"
                        disableElevation
                        endIcon={<SyncIcon />}
                        onClick={syncConsolidatedInvoices}
                        disabled={!isValid}
                    >
                        {t("converter.sync")}
                    </Button>
                </Stack>
            </Box>
        </Container>
    );
};

export default ConsolidatedInvoicesPreview;
