import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
    Box,
    Skeleton,
    styled,
    Typography,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    tableCellClasses,
    TableBody,
    TablePagination,
    Stack,
    IconButton,
    Button,
} from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SearchIcon from "@mui/icons-material/Search";
import invoicesService from "../../../services/invoices";

import { useEffect, useState } from "react";
import LightTooltip from "../../ui/LightTooltip";
import CustomFilledSelect from "../../ui/CustomFilledSelect";

const rowsSkeleton = [];

for (let i = 0; i < 4; i++) {
    rowsSkeleton.push(<Skeleton variant="rounded" width="100%" height={100} />);
}

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const InvoicesHistSync = (props) => {
    const {
        dataHist,
        showConversions,
        setIsLoading,
        setCorrelativeSync,
        setShowSyncDetails,
        setShowHistSync,
        setDetailsHist,
        setInvoicesStatus,
        replaceInvBill,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);
    const [currentHist, setCurrenHist] = useState("");

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "sync", title: t("converter.synchronization") },
        { name: "date", title: t("payment.date") },
        {
            name: "numInvoices",
            title: replaceInvBill(t("converter.numInvoices")),
            align: "center",
        },
        { name: "user", title: t("converter.user") },
        { name: "status", title: t("converter.status") },
        { name: "actions", title: <></> },
    ];

    useEffect(() => {
        if (dataHist.length > 0) {
            const newId = dataHist[0]._id;

            // Solo establece el nuevo historial si es diferente del anterior
            setCurrenHist((prevHist) => {
                if (prevHist !== newId) {
                    return newId;
                }
                return prevHist; // No cambies el valor si es igual al anterior
            });
        }
    }, [dataHist]);

    useEffect(() => {
        (async () => {
            if (dataHist.length > 0 && currentHist !== "") {
                let currentId = dataHist.find(
                    (item) => item._id.toString() === currentHist.toString()
                );

                let params = {};

                if (
                    currentId.grouped_invoice_id &&
                    currentId.grouped_invoice_id !== null
                ) {
                    params.grouped_invoice_id =
                        currentId.grouped_invoice_id._id;
                } else {
                    params.invoice_id = currentId.invoices[0]._id;
                }

                await invoicesService
                    .getHistorySync({
                        invoice_type: 1,
                        company_id: todos.companyID,
                        ...params,
                    })
                    .then(async (data) => {
                        const newRows = data.syncs.map((itemAux) => {
                            return {
                                id: itemAux._id,
                                sync: itemAux.correlative,
                                name: itemAux.name,
                                numInvoices: itemAux.invoices.length,
                                date: itemAux.current_date
                                    ? moment
                                          .utc(itemAux.current_date)
                                          .format(todos.dateFormat)
                                    : "-",
                                user: itemAux.user_id
                                    ? itemAux.user_id.user_name
                                    : "-",
                                status: (
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="left"
                                    >
                                        {/* // 1: Sincronizado, 2: Sincronizado parcial, 3: Fallido. */}
                                        {itemAux.id_status === 1 && (
                                            <UploadFileIcon
                                                sx={{ color: "#34bd6a" }}
                                                fontSize="inherit"
                                            />
                                        )}
                                        {itemAux.id_status === 2 && (
                                            <UploadFileIcon
                                                sx={{ color: "#FFB23F" }}
                                                fontSize="inherit"
                                            />
                                        )}
                                        {itemAux.id_status === 3 && (
                                            <CloseIcon
                                                sx={{ color: "#DA0069" }}
                                                fontSize="inherit"
                                            />
                                        )}
                                        &nbsp;
                                        <Typography>
                                            {itemAux.id_status === 1
                                                ? t("converter.synchronized")
                                                : itemAux.id_status === 2
                                                ? t("converter.partialSync")
                                                : t("converter.invalid2")}
                                        </Typography>
                                    </Stack>
                                ),
                            };
                        });
                        setRows(newRows);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })();
    }, [
        currentHist,
        dataHist,
        setDetailsHist,
        setInvoicesStatus,
        t,
        todos.companyID,
        todos.dateFormat,
    ]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleNumInvoices = async (id) => {
        setIsLoading(true);
        await invoicesService
            .getHistorySync({
                company_id: todos.companyID,
                sync_id: id,
                invoice_type: 1,
            })
            .then(async (data) => {
                setCorrelativeSync(data.syncs[0].correlative);
                showConversions(data.syncs[0].invoices, "sync");
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const showDetails = async (e, id) => {
        setIsLoading(true);

        await invoicesService
            .getHistorySync({
                sync_id: id,
                invoice_type: 1,
                company_id: todos.companyID,
            })
            .then(async (data) => {
                const newRows = data.syncs[0].invoices
                    .filter((item) => item.id_status !== 6)
                    .map((itemAux) => {
                        return {
                            id: itemAux._id,
                            invoice: itemAux.invoice_id,
                            filename: itemAux.uploaded_file,
                            error: itemAux.error_type,
                        };
                    });
                setInvoicesStatus({
                    total: data.syncs[0].invoices.length,
                    failed: data.syncs[0].invoices.filter(
                        (item) => item.id_status !== 6
                    ).length,
                    success: data.syncs[0].invoices.filter(
                        (item) => item.id_status === 6
                    ).length,
                });
                setDetailsHist(newRows);
            })
            .catch((err) => {
                console.log(err);
            });

        setShowHistSync(false);
        setShowSyncDetails(true);
        setIsLoading(false);
    };

    const handleChange = (event) => {
        setCurrenHist(event.target.value);
    };

    return (
        <>
            <Stack direction="row" spacing={3} alignItems="center">
                <Typography variant="h1">{t("converter.syncHist")}</Typography>
                {dataHist.length > 1 && (
                    <Box width="15%">
                        <CustomFilledSelect
                            required
                            label={t("subscription.conversion")}
                            value={currentHist}
                            onChange={handleChange}
                            name="ecActivity"
                            values={dataHist}
                            idName="_id"
                            valueName="name"
                        />
                    </Box>
                )}
            </Stack>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                sx={{
                                    borderCollapse: "separate",
                                    borderSpacing: "0 6px",
                                    minWidth: 1300,
                                }}
                            >
                                <TableHead>
                                    <TableRow
                                        sx={{ "& th": { border: "none" } }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={
                                                    selected.length > 0 &&
                                                    selected.length <
                                                        rows.length
                                                }
                                                checked={
                                                    rows.length > 0 &&
                                                    selected.length ===
                                                        rows.length
                                                }
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        {columns.map((headCell) => (
                                            <StyledTableCell
                                                key={headCell.name}
                                                align={headCell.align ?? "left"}
                                                style={{
                                                    width: headCell.width,
                                                }}
                                            >
                                                {headCell.title}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <StyledTableRow
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                        onClick={() =>
                                                            handleClick(row.id)
                                                        }
                                                    />
                                                </TableCell>
                                                <StyledTableCell>
                                                    {row.sync}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.date}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        onClick={() =>
                                                            handleNumInvoices(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        {row.numInvoices}
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.user}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {row.status}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <LightTooltip
                                                        title={t(
                                                            "converter.seeDetails"
                                                        )}
                                                    >
                                                        <IconButton
                                                            onClick={(e) =>
                                                                showDetails(
                                                                    e,
                                                                    row.id
                                                                )
                                                            }
                                                        >
                                                            <SearchIcon
                                                                fontSize="small"
                                                                color="primary"
                                                            />
                                                        </IconButton>
                                                    </LightTooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <StyledTableRow>
                                            <TableCell colSpan={6} />
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[
                                5,
                                10,
                                15,
                                { label: t("miscellaneous.all"), value: -1 },
                            ]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                            labelDisplayedRows={(page) =>
                                `${page.from}-${
                                    page.to === -1 ? page.count : page.to
                                } ${t("team.unlinkText2")} ${page.count}`
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default InvoicesHistSync;
